<template>
  <div class="cnt">
    <div class="img">
      <img
        class="backimg"
        src="https://oss.hr24.com.cn/public/0acc2526446e4ef9bac9ff7674a060ef.jpg?BUCKET=jpg-bucket"
        alt=""
      />
    </div>
    <div class="test-position">
      <wx-open-launch-weapp id="launch-btn" :username="hejkmAppid" :path="path">
        <script type="text/wxtag-template">
                    <style>
          .btn {
            font-size: 18px;
            width: 155px;
            margin: auto;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            color: #fff;
            letter-spacing: 1px;
            text-shadow: rgba(0, 0, 0, 0.9) 0px 1px 2px;
            background: #ff8f00;
            font-weight: 600;
            border-radius: 4px;
            box-shadow: rgba(64, 62, 62, 0.25) 2px 4px 8px 1px;
          }
                    </style>
                    <div class="btn" >打开小程序</div>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>

<script>
import { get_signature } from "@/api/login";

export default {
  name: "video_number",
  props: {
    msg: String,
  },
  data: function () {
    return {
      hejkmAppid: "gh_d625999d4888",
      path: "pages/job_wanted/job_wanted.html", // 一定要以 .html 结尾
      // xcmAppid:"gh_0ed5d82fd775",
      appid: "wxbc63cd55fd639ae5",
    };
  },
  created() {
    if (this.$route.query.id) {
      this.path =
        "packageA/pages/job_details/job_details.html" +
        "?is_online=0&position_live=true&id=" +
        this.$route.query.id;
    }
    this.setJSAPI();
  },
  mounted() {
    console.log("window.location.href", window.location.href);
  },
  methods: {
    handleLaunchFn(e) {
      console.log("success", e);
    },
    handleErrorFn(e) {
      console.log("fail", e.detail);
    },
    setJSAPI() {
      let that = this;
      get_signature({ url: window.location.href })
        .then((res) => {
          console.log("成功后", res);
          if (!res.code) {
            console.log(res);
            that.setConfig(res.data);
          }
        })
        .catch((err) => {
          console.error("失败", err);
        });
    },
    setConfig(data) {
      console.log("data", data);
      this.$wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: ["wx-open-launch-weapp"], //跳转小程序、获取定位信息、导航
        openTagList: ["wx-open-launch-weapp"], //打开的标签名
      });
      this.$wx.error(function (res) {
        console.log("错误", res);
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
      this.$wx.ready(function () {
        console.log("ready");
      });
      var btn = document.getElementById("btn");
      btn.addEventListener("click", function (e) {
        console.log("success");
      });
    },
  },
};
</script>

<style scoped >
.img {
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
}
.img > img {
  width: 100%;
  height: 100%;
  position: relative;
}
.test-position {
  position: absolute;
  z-index: 10;
  top: 25%;
  width: 155px;
  left: 50%;
  margin-left: -78px;
}
</style>
